'use client';

import { motion } from "motion/react";
import { cn } from '@/utils';
const Spinner = ({
  inline,
  message,
  variant,
  className,
  wrapperClasses
}) => {
  const classes = cn({
    'spinner': true,
    'spinner-inline': inline,
    [`spinner-${variant}`]: variant,
    [className]: className
  });
  const animationConfig = (delay = 0) => ({
    duration: 1.25,
    delay,
    repeat: Infinity,
    ease: "easeInOut"
  });
  const SpinnerLogo = () => {
    return <div className="spinner-logo" data-sentry-component="SpinnerLogo" data-sentry-source-file="Spinner.jsx">
        <svg viewBox="0 0 150 150" className="overflow-visible" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="Spinner.jsx">
          <motion.path initial={{
          opacity: 0,
          y: -20
        }} animate={{
          opacity: [0, 0.8, 0],
          y: [20, 0, -20]
        }} transition={animationConfig(0)} d="M20 52C20 40.799 20 35.1984 22.1799 30.9202C24.0973 27.1569 27.1569 24.0973 30.9202 22.1799C35.1984 20 40.799 20 52 20H118C129.201 20 134.802 20 139.08 22.1799C142.843 24.0973 145.903 27.1569 147.82 30.9202C150 35.1984 150 40.799 150 52V118C150 129.201 150 134.802 147.82 139.08C145.903 142.843 142.843 145.903 139.08 147.82C134.802 150 129.201 150 118 150H52C40.799 150 35.1984 150 30.9202 147.82C27.1569 145.903 24.0973 142.843 22.1799 139.08C20 134.802 20 129.201 20 118V52Z" fill="#225CF4" data-sentry-element="unknown" data-sentry-source-file="Spinner.jsx" />

          <motion.path initial={{
          opacity: 0,
          y: 20
        }} animate={{
          opacity: [0, 0.8, 0],
          y: [-20, 0, 20]
        }} transition={animationConfig(0.1)} d="M0 32C0 20.799 0 15.1984 2.17987 10.9202C4.09734 7.15695 7.15695 4.09734 10.9202 2.17987C15.1984 0 20.799 0 32 0H98C109.201 0 114.802 0 119.08 2.17987C122.843 4.09734 125.903 7.15695 127.82 10.9202C130 15.1984 130 20.799 130 32V98C130 109.201 130 114.802 127.82 119.08C125.903 122.843 122.843 125.903 119.08 127.82C114.802 130 109.201 130 98 130H32C20.799 130 15.1984 130 10.9202 127.82C7.15695 125.903 4.09734 122.843 2.17987 119.08C0 114.802 0 109.201 0 98V32Z" fill="#5E4AF9" data-sentry-element="unknown" data-sentry-source-file="Spinner.jsx" />

          <motion.path initial={{
          scale: 0.95,
          opacity: 0,
          y: 10
        }} animate={{
          scale: [0.95, 1, 0.95],
          opacity: [0, 1, 0],
          y: [10, 0, -10]
        }} transition={animationConfig(0.2)} fillRule="evenodd" clipRule="evenodd" d="M69.4768 47.7306C66.3688 47.7306 64 50.16 64 53.1781V100.807L64 100.822L64 100.836V101.011C64 101.077 63.9981 101.142 63.9945 101.207C63.7934 108.307 58.0525 114 51 114C43.8203 114 38 108.1 38 100.822C38 93.5439 43.8203 87.6439 51 87.6439C52.7291 87.6439 54.3795 87.9861 55.8886 88.6074V53.1953C55.8886 45.9078 61.9721 40 69.4768 40H98.3003C105.805 40 112 45.8906 112 53.1781V87.137C112 87.1946 111.999 87.2519 111.996 87.3088C111.999 87.4201 112 87.5318 112 87.6438C112 94.9219 106.18 100.822 99 100.822C91.8203 100.822 86 94.9219 86 87.6438C86 80.3658 91.8203 74.4657 99 74.4657C100.744 74.4657 102.408 74.8139 103.928 75.4455V53.1953C103.928 50.1771 101.408 47.7306 98.3003 47.7306H69.4768Z" fill="white" data-sentry-element="unknown" data-sentry-source-file="Spinner.jsx" />
        </svg>
      </div>;
  };
  return <div className={cn({
    "spinner-wrapper": true,
    "my-16": !inline,
    [wrapperClasses]: wrapperClasses
  })} data-sentry-component="Spinner" data-sentry-source-file="Spinner.jsx">
      <div className={classes}>
        {!inline && <SpinnerLogo />}
        {message && <p className="spinner-message">{message}</p>}
      </div>
    </div>;
};
export default Spinner;