'use client';

import dynamicIconImports from "lucide-react/dynamicIconImports";
import dynamic from "next/dynamic";
import BrandIcon from './BrandIcon';
import { camelToKebab, cn } from '@/utils';
const icons = Object.keys(dynamicIconImports);
const icons_components = {};
for (const name of icons) {
  const NewIcon = dynamic(dynamicIconImports[name], {
    ssr: false
  });
  icons_components[name] = NewIcon;
}
const LucideDynamicIcon = ({
  name,
  ...props
}) => {
  const Icon = icons_components[name];
  if (!Icon) return null;
  return <Icon {...props} data-sentry-element="Icon" data-sentry-component="LucideDynamicIcon" data-sentry-source-file="index.jsx" />;
};
export default function Icon({
  name,
  color,
  size,
  iconProps,
  type = 'lucide',
  strokeWidth = 1.5,
  className,
  ...props
}) {
  const classes = cn({
    'icon': true,
    [size]: size,
    [className]: className
  });
  if (type === 'brand') {
    return <span className={classes} {...props}>
        <BrandIcon icon={name} />
      </span>;
  }
  return <span className={classes} {...props} data-sentry-component="Icon" data-sentry-source-file="index.jsx">
      <LucideDynamicIcon color={color} strokeWidth={strokeWidth} {...iconProps} name={camelToKebab(name)} data-sentry-element="LucideDynamicIcon" data-sentry-source-file="index.jsx" />
    </span>;
}